import { useCallback, useMemo, useState } from "react";
import {
  faCar,
  faFilePdf,
  faHashtag,
  faIdBadge,
  faMagnifyingGlass,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  ProgressBar,
  Row,
} from "react-bootstrap";
import Select from "react-select";
import { useGetData } from "../../../hooks/useGetData";
import { UsuarioInterface } from "../../../interfaces/UsuarioInterface";
import useFetch from "../../../hooks/useFetch";

//interfaces
import { ActividadBusqueda } from "../../../interfaces/ActividadBusqueda";
import { ColumnDef } from "@tanstack/react-table";
import CustomTable from "../../Formularios/CustomTable/CustomTable";
import TimelineModal from "../../Formularios/TimelineModal/TimelineModal";
import { Etapa } from "../../../interfaces/ActividadBusqueda";
import useDownloadPDF from "../../../hooks/useDownloadPDF";
import { SucursalInterface } from "../../../interfaces/SucursalInterface";

interface formTextInputs {
  sucursal_id: string | undefined;
  tecnico_id: string | undefined;
  actividad_id: string | undefined;
  placas: string | undefined;
  economico: string | undefined;
}

const BusquedaGeneral = () => {
  const { data: sucursales } = useGetData("/sucursal", "sucursal");
  const { data: tecnicos } = useGetData("/usuario?rol_id[gt]?=1", "tecnicos");
  const [formTextInputs, setFormTextInputs] = useState<formTextInputs>({
    sucursal_id: "",
    tecnico_id: "",
    actividad_id: "",
    placas: "",
    economico: "",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const { sendRequest } = useFetch();
  const [actividades, setActividades] = useState<ActividadBusqueda[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [actividadesEtapas, setActividadEtapas] = useState<Etapa[]>();
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
  const { mostrarPDF } = useDownloadPDF(setLoadingPDF);

  //Función que trata los servicios traidos de la BD.
  const transformData = useCallback((variable: any) => {
    setActividades(variable.data);
    setLoading(false);
  }, []);

  const findServices = () => {
    setLoading(true);
    sendRequest(
      {
        url: "/get_actividades",
        method: "POST",
        body: formTextInputs,
      },
      transformData
    );
  };

  const columns = useMemo<ColumnDef<ActividadBusqueda>[]>(
    () => [
      {
        header: "Órden de servicio",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Sucursal",
        accessorFn: (row) =>
          row.servicio.sucursal.nombre ? row.servicio.sucursal.nombre : "",
        id: "sucursal_nombre",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Folio",
        accessorFn: (row) => (row.servicio.folio ? row.servicio.folio : ""),
        id: "Folio",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Tipo de servicio",
        accessorFn: (row) => row.tipo_servicio.nombre,
        id: "tipo_servicio",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "VIN",
        accessorFn: (row) => (row.unidad.vin ? row.unidad.vin : ""),
        id: "vin",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Placas",
        accessorFn: (row) => (row.unidad.placa ? row.unidad.placa : ""),
        id: "placa",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Técnico",
        accessorFn: (row) =>
          row.usuario
            ? `${row.usuario.nombre} ${row.usuario.apellido_paterno}`
            : "",
        id: "tecnico",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Estatus",
        accessorFn: (row) => (
          <h5
            style={{ cursor: "pointer" }}
            onClick={() => {
              setShowModal(true);
              setActividadEtapas(row.etapa);
            }}
          >
            <Badge pill bg="secondary">
              {row.tipo_etapa.nombre}
            </Badge>
          </h5>
        ),
        id: "tipo_etapa",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            {/** Boton de despliegue del PDF */}
            {info.row.original.etapa.find(
              (etapa) => etapa.tipo_etapa_id >= 3
            ) && (
              <Button
                title="Obtener el PDF de este servicio"
                style={{ backgroundColor: "#5DADE2", borderColor: "white" }}
                disabled={loadingPDF}
                onClick={() => mostrarPDF(info.row.original.id)}
              >
                <FontAwesomeIcon icon={faFilePdf} />
              </Button>
            )}
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [loadingPDF, mostrarPDF]
  );

  return (
    <Container>
      <h1>Búsqueda General</h1>
      {loadingPDF && (
        <ProgressBar
          style={{ marginBottom: "1rem" }}
          label="Creando PDF..."
          animated
          now={100}
        />
      )}
      <Form>
        <Row className="mb-3" style={{ textAlign: "left" }}>
          <InputGroup as={Col}>
            <InputGroup.Text id="btnGroupAddon">
              <FontAwesomeIcon icon={faHashtag} />
            </InputGroup.Text>
            <Form.Control
              type="number"
              step={1}
              min={1}
              placeholder="Órden de servicio"
              aria-label="Id del servicio"
              aria-describedby="btnGroupAddon"
              onChange={(event) =>
                setFormTextInputs((prev: any) => {
                  return { ...prev, actividad_id: event.target.value };
                })
              }
            />
          </InputGroup>
          <Form.Group as={Col}>
            <Select
              onChange={(value: any) => {
                setFormTextInputs((prev: any) => {
                  return { ...prev, sucursal_id: value ? value.value : "" };
                });
              }}
              placeholder="Sucursal"
              isClearable
              options={sucursales?.map((sucursal: SucursalInterface) => {
                return {
                  value: sucursal.id,
                  label: sucursal.nombre,
                };
              })}
            />
          </Form.Group>
          <InputGroup as={Col}>
            <InputGroup.Text id="btnGroupAddon">
              <FontAwesomeIcon icon={faIdBadge} />
            </InputGroup.Text>
            <Select
              onChange={(value: any) => {
                setFormTextInputs((prev: any) => {
                  return { ...prev, tecnico_id: value ? value.value : "" };
                });
              }}
              placeholder="Técnico asignado"
              isClearable
              options={tecnicos?.map((tecnico: UsuarioInterface) => {
                return {
                  value: tecnico.id,
                  label: tecnico.nombre + " " + tecnico.apellido_paterno,
                };
              })}
            />
          </InputGroup>
        </Row>
        <hr />
        <Row className="mb-3 col-4" style={{ textAlign: "left" }}>
          <InputGroup as={Col}>
            <InputGroup.Text id="btnGroupAddon">
              <FontAwesomeIcon icon={faCar} />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="Placas"
              aria-label="Placas"
              aria-describedby="btnGroupAddon"
              onChange={(event) =>
                setFormTextInputs((prev: any) => {
                  return { ...prev, placas: event.target.value };
                })
              }
            />
          </InputGroup>
        </Row>
        <Button
          style={{
            float: "right",
            backgroundColor: "#21618C",
            marginBottom: "10px",
          }}
          title="Buscar"
          onClick={findServices}
          disabled={
            !Object.values(formTextInputs).find((element) => element !== "")
              ? true
              : false
          }
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} /> Buscar
        </Button>
        {loading && (
          <FontAwesomeIcon
            icon={faSpinner}
            pulse
            style={{ fontSize: "100", marginTop: 30 }}
          />
        )}
        {actividades && actividades?.length > 0 && (
          <CustomTable
            {...{
              data: actividades,
              columns,
            }}
          />
        )}
        {!loading && actividades && actividades?.length === 0 && (
          <h3 style={{ marginTop: "100px" }}>
            No se encontraron resultados con esos parámetros
          </h3>
        )}
      </Form>
      {/** Modal de cobro */}
      {showModal && (
        <TimelineModal
          actividadEtapas={actividadesEtapas}
          showModal={showModal}
          handleCloseModal={() => setShowModal(false)}
        />
      )}
    </Container>
  );
};

export default BusquedaGeneral;
