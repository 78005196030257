//librerías
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  ButtonGroup,
  Container,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

//estilos
const classes = require("./Main.module.css").default;

interface Props {
  showModal: boolean;
  handleCloseModal: () => void;
  titulo: string;
}

/**
 * Screen Main
 * @description: Es la pantalla que contiene el nav de la aplicación.
 * @date 14/11/2022.
 * @returns JSX del Screen.
 */

const Main = () => {
  const authContext = useContext(AuthContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      {/**Navbar */}
      <Navbar
        bg="light"
        variant="light"
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 2,
          background:
            "linear-gradient(135deg, rgba(113,0,0,1) 0%, rgba(248,249,250,1) 10%, rgba(248,249,250,1) 100%)",
        }}
      >
        <Container>
          <Navbar.Brand href="/main">
            <img
              src={require("../../img/nmp.png")}
              alt="NMP"
              width={"50"}
              height={"40"}
            />
            <img
              src={require("../../img/skyangel.png")}
              alt="SkyTech"
              width={"35"}
              height={"30"}
            />
            SkyTech
          </Navbar.Brand>
          <Nav className="me-auto">
            <Link to="" className={classes.navlink}>
              Dashboard
            </Link>
            <NavDropdown
              style={{ marginRight: "20px" }}
              title="Servicios"
              menuVariant="dark"
            >
              <Link className={classes.dropdownItem} to="servicios/capturados">
                Capturados
              </Link>
              <Link className={classes.dropdownItem} to="servicios/programados">
                Programados
              </Link>
              <Link className={classes.dropdownItem} to="servicios/por_firmar">
                Por firmar
              </Link>
              <Link className={classes.dropdownItem} to="servicios/atendidos">
                Atendidos
              </Link>
              <Link className={classes.dropdownItem} to="servicios/en_falso">
                Salidas en Falso
              </Link>
            </NavDropdown>
            {authContext.tokenCan("Usuario:show") && (
              <Link className={classes.navlink} to="catalogos">
                Catálogos
              </Link>
            )}
            <NavDropdown title="Administración" menuVariant="dark">
              {authContext.tokenCan("Cobro:store") && (
                <Link className={classes.dropdownItem} to="servicios/cobrar">
                  Cobros
                </Link>
              )}
              {authContext.tokenCan("Cobro:store") && (
                <Link className={classes.dropdownItem} to="servicios/cobrados">
                  Servicios cobrados
                </Link>
              )}
              {authContext.tokenCan("Reportes:download") && (
                <Link className={classes.dropdownItem} to="reportes">
                  Reportes
                </Link>
              )}
              {authContext.tokenCan("Pago:store") && (
                <Link className={classes.dropdownItem} to="servicios/pagar">
                  Pagar a Proveedores
                </Link>
              )}
              {authContext.tokenCan("Pago:store") && (
                <Link className={classes.dropdownItem} to="servicios/pagados">
                  Proveedores con pago
                </Link>
              )}
            </NavDropdown>
          </Nav>
          <ButtonGroup className="mb-2">
            <Button
              variant="outline-primary"
              style={{ float: "right" }}
              title="Búsqueda General"
            >
              <Link className={classes.linkInButton} to="busqueda">
                <FontAwesomeIcon icon={faMagnifyingGlass} /> Búsqueda
              </Link>
            </Button>
            <Button
              variant="outline-dark"
              style={{ float: "right" }}
              onClick={authContext.logout}
              title="Cerrar sesión"
            >
              <FontAwesomeIcon icon={faRightFromBracket} /> Cerrar sesión
            </Button>
          </ButtonGroup>
        </Container>
      </Navbar>
      <Outlet />
    </>
  );
};

export default Main;
