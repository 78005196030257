import { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import useFetch from "../../../../hooks/useFetch";
import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";
import SuccessMessage from "../../../Mensajes/SuccessMessage";
import ServerMessage from "../../../Mensajes/ServerMessage";

/**
 * ClienteCrear Component
 * @description: Componente que permite agregar un nuevo cliente.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de clientes.
 */

const ClienteCrear = () => {
  const [show, setShow] = useState(false);
  const { sendRequest, error, setError, serverMessage, setServerMessage } =
    useFetch();

  //Formulario
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      razon_social: "",
      nombre_comercial: "",
      estatus: "1",
    },
  });

  //Función que hace el POST para crear el cliente.
  const createCliente = (data: any) => {
    sendRequest(
      {
        url: `/cliente`,
        method: "POST",
        body: data,
      },
      (variable: any) => {
        setShow(true);
        reset({
          razon_social: "",
          nombre_comercial: "",
          estatus: "1",
        });
      }
    );
  };

  return (
    <Container>
      <h1>Agregar cliente</h1>
      {/** Alerta de guardado */}
      {show && (
        <SuccessMessage
          header="Cliente agregado"
          body="El cliente ha sido agregado de manera correcta."
          onCloseHandler={() => setShow(false)}
        />
      )}
      {serverMessage && (
        <ServerMessage
          message={serverMessage}
          onCloseHandler={() => setServerMessage(undefined)}
        />
      )}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Formulario */}
      <Form onSubmit={handleSubmit(createCliente)}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" className="mb-3">
            <Form.Label>Razón Social</Form.Label>
            <Controller
              name="razon_social"
              control={control}
              rules={{
                required: true,
                maxLength: 100,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
            {errors.razon_social && (
              <Form.Text style={{ color: "red" }}>
                La razón social es obligatoria y no puede ser mayor a 100
                caracteres.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} md="6" className="mb-3">
            <Form.Label>Nombre Comercial</Form.Label>
            <Controller
              name="nombre_comercial"
              control={control}
              rules={{
                required: true,
                maxLength: 100,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
            {errors.nombre_comercial && (
              <Form.Text style={{ color: "red" }}>
                El nombre comercial es obligatorio y no puede ser mayor a 100
                caracteres.
              </Form.Text>
            )}
          </Form.Group>
        </Row>

        <Button
          style={{ backgroundColor: "#21618C", borderColor: "white" }}
          type="submit"
        >
          Guardar cambios
        </Button>
      </Form>
    </Container>
  );
};

export default ClienteCrear;
