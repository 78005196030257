import React, { useState, useCallback } from "react";
import { UsuarioInterface } from "../interfaces/UsuarioInterface";

type AuthContextProps = {
  token: string | null;
  usuario: UsuarioInterface | null;
  permisos: string[] | null;
  isLoggedIn: boolean;
  login: (
    usuario: UsuarioInterface | null,
    token: string | null,
    permisos: string[]
  ) => void;
  tokenCan: (permiso: string) => boolean;
  logout: () => void;
};

export const AuthContext = React.createContext({} as AuthContextProps);

const retrieveStoredToken = () => {
  const storedToken = localStorage.getItem("token");
  return storedToken;
};

const retrieveStoredUsuario = () => {
  let storedUsuario: UsuarioInterface | null = null;
  if (localStorage.getItem("usuario")) {
    storedUsuario = JSON.parse(localStorage.getItem("usuario") || "{}");
  }

  return storedUsuario;
};

const retireveStoredPermisos = () => {
  let storedPermisos: string[] = [];

  if (localStorage.getItem("permisos")) {
    storedPermisos = JSON.parse(localStorage.getItem("permisos") || "{}");
  }

  return storedPermisos;
};

export const AuthContextProvider = ({ children }: any) => {
  const tokenData = retrieveStoredToken();
  const usuarioData = retrieveStoredUsuario();
  const permisosData = retireveStoredPermisos();

  //Lógica de token
  let initialToken: string | null = null;

  if (tokenData) {
    initialToken = tokenData;
  }

  const [token, setToken] = useState<string | null>(initialToken);

  const userIsLoggedIn = !!token;

  //Lógica de usuario
  let initialUsuario: UsuarioInterface | null = null;

  if (usuarioData) {
    initialUsuario = usuarioData;
  }

  const [usuario, setUsuario] = useState<UsuarioInterface | null>(
    initialUsuario
  );

  //Logica de permisos
  let initialPermisos: string[] = [];

  if (permisosData) {
    initialPermisos = permisosData;
  }

  const [permisos, setPermisos] = useState<string[]>(initialPermisos);

  const logoutHandler = useCallback(() => {
    setToken(null);
    setUsuario(null);
    setPermisos([]);
    localStorage.removeItem("token");
    localStorage.removeItem("usuario");
    localStorage.removeItem("permisos");
    localStorage.removeItem("plataformas");
  }, []);

  const loginHandler = (
    usuario: UsuarioInterface | null,
    token: string | null,
    permisos: string[]
  ) => {
    setToken(token);
    if (token !== null) {
      localStorage.setItem("token", token);
    }
    setUsuario(usuario);
    if (usuario !== null) {
      localStorage.setItem("usuario", JSON.stringify(usuario));
    }
    setPermisos(permisos);
    if (permisos.length > 0) {
      localStorage.setItem("permisos", JSON.stringify(permisos));
    }
  };

  const tokenCan = (permiso: string) => {
    return permisos.find((perm) => perm === permiso) ? true : false;
  };

  const contextValue = {
    token: token,
    usuario: usuario,
    permisos: permisos,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    tokenCan,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
