import { faTrash, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import Select from "react-select";
import ContactoInterface from "../../../interfaces/ContactoInterface";
import useFetch from "../../../hooks/useFetch";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Unidades } from "../../Formularios/Unidades/Unidades";
import { useGetData } from "../../../hooks/useGetData";
import { EstadoInterface } from "../../../interfaces/EstadoInterface";
import { SucursalInterface } from "../../../interfaces/SucursalInterface";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//estilos
const classes = require("./ServiciosCrear.module.css").default;

let bodyPost = {};

const ServiciosCrear = () => {
  const [contacto, setContacto] = useState<ContactoInterface[]>([]);
  const [actividad, setActividad] = useState<any[]>([]);
  const { data: sucursales } = useGetData("/sucursal", "sucursal");
  const [estados, setEstados] = useState<EstadoInterface[]>([]);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const navigate = useNavigate();

  const [currentContacto, setCurrentContacto] = useState<ContactoInterface>({
    idElement: undefined,
    nombre: "",
    correo: "",
    telefono_movil: "",
    telefono_fijo: "",
  });

  const { sendRequest } = useFetch();

  useEffect(() => {
    sendRequest(
      {
        url: `/estado`,
      },
      (variable: any) => {
        setEstados(
          variable.data.map((estado: EstadoInterface) => {
            return { value: estado.id, label: estado.nombre };
          })
        );
      }
    );
  }, [sendRequest]);

  //Validaciones del formulario
  const formSchema = Yup.object().shape({
    folio: Yup.string().nullable().optional(),
    sucursal: Yup.object().required("Es necesario seleccionar la sucursal"),
    domicilio_sucursal: Yup.string()
      .nullable()
      .optional()
      .max(200, "El domicilio no puede ser mayor a 200 caracteres"),
    estado: Yup.object().required("Es necesario seleccionar un estado"),
    latitud_sucursal: Yup.string().required("La latitud es obligatoria"),
    longitud_sucursal: Yup.string().required("La longitud es obligatoria"),
    notas_sucursal: Yup.string()
      .nullable()
      .optional()
      .max(200, "Las observaciones no puede ser mayores a 200 caracteres"),
  });

  //Formulario
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  const agregarContactoHandler = () => {
    if (currentContacto.nombre === "") {
      toast.error("Se debe seleccionar el nombre del contacto", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else if (currentContacto.telefono_movil === "") {
      toast.error("Se debe agregar el telefono móvil del contacto", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setContacto((prev) => [
      ...prev,
      { ...currentContacto, idElement: contacto.length + 1 },
    ]);
    setCurrentContacto({
      idElement: undefined,
      nombre: "",
      correo: "",
      telefono_movil: "",
      telefono_fijo: "",
    });
  };

  //Función que hace el POST para crear el servicio.
  const createServicio = (data: any) => {
    if (actividad.length === 0) {
      toast.error("Se debe seleccionar una unidad para el servicio", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    } else {
      //Desactivar boton de guardar
      buttonRef.current!.disabled = true;
      bodyPost = {
        ...data,
        ultima_etapa: 1,
        contacto: [...contacto],
        actividad: [...actividad],
      };
    }
    sendRequest(
      {
        url: `/servicio`,
        method: "POST",
        body: bodyPost,
      },
      (variable: any) => {
        toast.success("Se guardó el servicio", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate(`/main/servicios/capturados`);
      }
    );
  };

  if (estados.length === 0 && sucursales) {
    return <Spinner animation="grow" variant="danger" />;
  }

  return (
    <Container className="mb-3">
      <h1>Agregar servicio</h1>
      <h2 className={classes.subtitulo}>Datos generales </h2>
      <Form onSubmit={handleSubmit(createServicio)}>
        <Row className="mb-3">
          <Form.Group as={Col} xs={6}>
            <Form.Label>Folio</Form.Label>
            <Form.Control
              {...register("folio", { maxLength: 50 })}
              placeholder="Ingresar el folio de órden de venta"
            />
            {errors.folio && (
              <Form.Text style={{ color: "red" }}>
                {errors.folio?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>

        <h2 className={classes.subtitulo}>Datos de la sucursal </h2>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Sucursal</Form.Label>
            <Controller
              name="sucursal"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={sucursales?.map((sucursal: SucursalInterface) => {
                    return {
                      value: sucursal.id,
                      label: sucursal.nombre,
                      domicilio: sucursal.domicilio,
                      latitud: sucursal.latitud,
                      longitud: sucursal.longitud,
                      estado: sucursal.estado,
                    };
                  })}
                  onChange={(sucursal: any) => {
                    setValue("sucursal", {
                      value: sucursal.value,
                      label: sucursal.label,
                    });
                    setValue("domicilio_sucursal", sucursal.domicilio);
                    setValue("latitud_sucursal", sucursal.latitud);
                    setValue("longitud_sucursal", sucursal.longitud);
                    setValue("estado", {
                      value: sucursal.estado.id,
                      label: sucursal.estado.nombre,
                    });
                  }}
                  placeholder="Seleccionar..."
                />
              )}
            />
            {errors.sucursal && (
              <Form.Text style={{ color: "red" }}>
                {errors.sucursal?.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Domicilio</Form.Label>
            <Form.Control
              {...register("domicilio_sucursal", {
                maxLength: 200,
              })}
            />
            {errors.domicilio_sucursal && (
              <Form.Text style={{ color: "red" }}>
                {errors.domicilio_sucursal?.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Estado</Form.Label>
            <Controller
              name="estado"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={estados}
                  placeholder="Seleccionar..."
                />
              )}
            />
            {errors.estado && (
              <Form.Text style={{ color: "red" }}>
                {errors.estado?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Latitud</Form.Label>
            <Form.Control
              type="number"
              step={0.00000000001}
              min={-90}
              max={90}
              {...register("latitud_sucursal", {
                maxLength: 50,
                required: true,
              })}
            />
            {errors.latitud_sucursal && (
              <Form.Text style={{ color: "red" }}>
                {errors.latitud_sucursal?.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Longitud</Form.Label>
            <Form.Control
              type="number"
              step={0.00000000001}
              min={-180}
              max={180}
              {...register("longitud_sucursal", {
                maxLength: 50,
                required: true,
              })}
            />
            {errors.longitud_sucursal && (
              <Form.Text style={{ color: "red" }}>
                {errors.longitud_sucursal?.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Observaciones</Form.Label>
            <Form.Control
              {...register("notas_sucursal", { maxLength: 200 })}
              as="textarea"
            />
            {errors.notas_sucursal && (
              <Form.Text style={{ color: "red" }}>
                {errors.notas_sucursal?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Nombre del contacto</Form.Label>
            <Form.Control
              onChange={(event) => {
                setCurrentContacto({
                  ...currentContacto,
                  nombre: event.target.value,
                });
              }}
              value={currentContacto.nombre}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="correo@mail.com"
              onChange={(event) => {
                setCurrentContacto({
                  ...currentContacto,
                  correo: event.target.value,
                });
              }}
              value={currentContacto.correo}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Teléfono móvil</Form.Label>
            <Form.Control
              placeholder="(55) 55555555"
              onChange={(event) => {
                setCurrentContacto({
                  ...currentContacto,
                  telefono_movil: event.target.value,
                });
              }}
              value={currentContacto.telefono_movil}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Teléfono fijo</Form.Label>
            <Form.Control
              placeholder="(55) 55555555"
              onChange={(event) => {
                setCurrentContacto({
                  ...currentContacto,
                  telefono_fijo: event.target.value,
                });
              }}
              value={currentContacto.telefono_fijo}
            />
          </Form.Group>

          <Col xs="auto">
            <Button
              title="Agregar contacto a este servicio"
              style={{
                backgroundColor: "#5DADE2",
                borderColor: "white",
                marginTop: "10px",
              }}
              onClick={agregarContactoHandler}
            >
              <FontAwesomeIcon icon={faUserPlus} />
            </Button>
          </Col>
        </Row>
        {contacto.length > 0 && (
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>nombre</th>
                <th>correo</th>
                <th>teléfono móvil</th>
                <th>teléfono fijo</th>
                <th>Quitar</th>
              </tr>
            </thead>
            <tbody>
              {contacto.map((cont, index) => (
                <tr key={index}>
                  <td>{cont.idElement}</td>
                  <td>{cont.nombre}</td>
                  <td>{cont.correo}</td>
                  <td>{cont.telefono_movil}</td>
                  <td>{cont.telefono_fijo}</td>
                  <td>
                    <Button
                      style={{
                        backgroundColor: "#5DADE2",
                        borderColor: "white",
                        marginTop: "10px",
                      }}
                      onClick={() =>
                        setContacto((prev) =>
                          prev.filter(
                            (contactoac) =>
                              contactoac.idElement !== cont.idElement
                          )
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
        <div style={{ marginTop: "15px" }}>
          <Unidades
            setValue={setValue}
            getValues={getValues}
            setActividad={setActividad}
            actividad={actividad}
          />
          <Button
            ref={buttonRef}
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginTop: "10px",
            }}
            type="submit"
          >
            Guardar
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default ServiciosCrear;
