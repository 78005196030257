import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import useFetch from "../../../hooks/useFetch";

//interfaces
import { Usuario } from "../../../interfaces/ServiciosDetalleInterface";
import ForbiddenMessage from "../../Mensajes/ForbiddenMessage";
import { ActividadShow } from "../../../interfaces/ActividadShowInterface";

interface Timestamp {
  fecha: string;
  hora: string;
}

//estilos
const classes = require("./ProgramarServicio.module.css").default;

const ProgramarServicio = () => {
  const { idActividad } = useParams();
  const { sendRequest, error, setError } = useFetch();
  const [actividad, setActividad] = useState<ActividadShow>();
  const [tecnicos, setTecnicos] = useState<Usuario[]>();
  const [tecnico, setTecnico] = useState<number>();
  const [timestamp, setTimestamp] = useState<Timestamp>({
    fecha: "",
    hora: "",
  });

  const navigate = useNavigate();

  let bodyPOST: { [key: string]: Object | string | number } = useMemo(() => {
    return { ultima_etapa: 2 };
  }, []);

  // Funciones para las fechas
  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };

  const formatDate = (date: Date) => {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(":")
    );
  };

  const [date] = formatDate(new Date()).split(" ");

  //Función que trata el detalle de servicio.
  const transformData = useCallback(
    (variable: any) => {
      if (variable.data.fecha) {
        let tempStamp = variable.data.fecha.split(" ");
        setTimestamp({ fecha: tempStamp[0], hora: tempStamp[1] });
      }
      setActividad(variable.data);
      setTecnico(variable.data.usuario?.id);
      bodyPOST["programar"] = variable.data.usuario ? false : true;
    },
    [bodyPOST]
  );

  //Función que trata el detalle de servicio.
  const transformDataTecnicos = useCallback((variable: any) => {
    setTecnicos(variable.data);
  }, []);

  //Se recibe el servicio a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/actividad/${idActividad}?includeAll=true`,
      },
      transformData
    );
  }, [sendRequest, transformData, idActividad]);

  //Se recibe el catalogo de tecnicos
  useEffect(() => {
    sendRequest(
      {
        url: `/usuario?rol_id[gt]?=1`,
      },
      transformDataTecnicos
    );
  }, [sendRequest, transformDataTecnicos]);

  //Función para programar un servicio
  const Programar = () => {
    if (!timestamp.fecha) {
      toast.error("Es necesaria la fecha", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!timestamp.hora) {
      toast.error("Es necesaria la hora", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (!tecnico) {
      toast.error("Es necesario asignar un técnico a la unidad", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    //Se actualiza el servicio
    bodyPOST["fecha"] = `${timestamp.fecha} ${timestamp.hora}`;
    bodyPOST["actividad_id"] = actividad!.id;
    bodyPOST["usuario_id"] = tecnico;
    sendRequest(
      {
        url: `/servicio/${actividad?.servicio.id}`,
        method: "PATCH",
        body: bodyPOST,
      },
      (variable: any) => {
        navigate(`/main/servicios/programados`);
        toast.success("Se programó el servicio", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    );
  };

  //Mientras se carga el contenido se muestra un spinner
  if (!actividad) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h1>Programación del servicio</h1>
      {/** Alerta de permisos */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      <h2 className={classes.subtitulo}>Datos generales </h2>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Fecha de servicio</Form.Label>
            <Form.Control
              type="date"
              min={date}
              onChange={(value) =>
                setTimestamp((prev) => {
                  return { ...prev, fecha: value.target.value };
                })
              }
              value={timestamp.fecha}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Hora de servicio</Form.Label>
            <Form.Control
              onChange={(value) =>
                setTimestamp((prev) => {
                  return { ...timestamp, hora: `${value.target.value}:00.000` };
                })
              }
              type="time"
              value={timestamp.hora}
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Folio</Form.Label>
            <Form.Control value={actividad.servicio.folio as string} disabled />
          </Form.Group>
        </Row>
      </Form>

      <h2 className={classes.subtitulo}>Datos de la sucursal </h2>
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Sucursal</Form.Label>
            <Form.Control
              value={actividad.servicio.sucursal.nombre as string}
              disabled
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Domicilio</Form.Label>
            <Form.Control
              value={actividad.servicio.domicilio_sucursal as string}
              disabled
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Estado</Form.Label>
            <Form.Control
              value={
                actividad.servicio.estado
                  ? actividad.servicio.estado.nombre
                  : ""
              }
              disabled
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Latitud</Form.Label>
            <Form.Control
              value={actividad.servicio.latitud_sucursal as string}
              disabled
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Longitud</Form.Label>
            <Form.Control
              value={actividad.servicio.longitud_sucursal as string}
              disabled
            />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Observaciones</Form.Label>
            <Form.Control
              value={
                actividad.servicio.notas_sucursal
                  ? actividad.servicio.notas_sucursal
                  : undefined
              }
              disabled
            />
          </Form.Group>
        </Row>
        {actividad.servicio.contacto &&
          actividad.servicio.contacto.length > 0 && (
            <Alert variant="secondary">
              <Alert.Heading>Contactos de instalación</Alert.Heading>
              <>
                {actividad.servicio.contacto?.map((contacto) => {
                  return (
                    <Row key={contacto.id} className="mb-3">
                      <Form.Group as={Col}>
                        <Form.Label>Contacto de instalación</Form.Label>
                        <Form.Control
                          value={contacto.nombre as string}
                          disabled
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          value={contacto.correo as string}
                          disabled
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Teléfono móvil</Form.Label>
                        <Form.Control
                          value={contacto.telefono_movil as string}
                          disabled
                        />
                      </Form.Group>

                      <Form.Group as={Col}>
                        <Form.Label>Teléfono fijo</Form.Label>
                        <Form.Control
                          value={contacto.telefono_fijo as string}
                          disabled
                        />
                      </Form.Group>
                    </Row>
                  );
                })}
              </>
            </Alert>
          )}
      </Form>
      <>
        <h2 className={classes.subtitulo}>Datos de instalación y unidades</h2>

        <Table responsive="sm">
          <thead>
            <tr>
              <th>Servicio</th>
              <th>Marca</th>
              <th>Submarca</th>
              <th>Modelo</th>
              <th>VIN</th>
              <th>Placas</th>
              <th>Color</th>
              <th>Técnico</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{actividad.tipo_servicio.nombre}</td>
              <td>{actividad.unidad.marca_unidad?.nombre}</td>
              <td>{actividad.unidad.submarca}</td>
              <td>{actividad.unidad.modelo}</td>
              <td>{actividad.unidad.vin}</td>
              <td>{actividad.unidad.placa}</td>
              <td>{actividad.unidad.color}</td>
              <td>
                <Select
                  placeholder="Seleccionar técnico"
                  isClearable
                  defaultValue={
                    actividad.usuario
                      ? {
                          value: actividad.usuario.id,
                          label: `${actividad.usuario?.nombre} ${actividad.usuario?.apellido_paterno}`,
                        }
                      : undefined
                  }
                  onChange={(value) => {
                    setTecnico(value?.value);
                  }}
                  options={tecnicos?.map((tecnico: Usuario) => {
                    return {
                      value: tecnico.id,
                      label: `${tecnico.nombre} ${tecnico.apellido_paterno}`,
                    };
                  })}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </>
      <Button
        style={{
          backgroundColor: "#21618C",
          borderColor: "white",
          marginTop: "10px",
        }}
        onClick={Programar}
      >
        Programar
      </Button>
    </Container>
  );
};

export default ProgramarServicio;
