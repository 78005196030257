import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import useFetch from "../../../../hooks/useFetch";

//interfaces
import { ClientesInterface } from "../../../../interfaces/ClientesInterface";
import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";
import SuccessMessage from "../../../Mensajes/SuccessMessage";
import ServerMessage from "../../../Mensajes/ServerMessage";

//estilos
const classes = require("./ClienteEdit.module.css").default;

/**
 * UusarioEdit Component
 * @description: Componente que permite editar un cliente.
 * @date 6/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de clientes.
 */

const ClienteEdit = () => {
  const { idCliente } = useParams();
  const { sendRequest, error, setError, serverMessage, setServerMessage } =
    useFetch();
  const [cliente, setCliente] = useState<ClientesInterface>();
  const [show, setShow] = useState(false);

  //Variables de formulario
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      razon_social: cliente?.razon_social,
      nombre_comercial: cliente?.nombre_comercial,
    },
  });

  //Función que trata el detalle de cliente.
  const transformData = useCallback(
    (variable: any) => {
      setCliente(variable.data);
      reset(variable.data);
    },
    [reset]
  );

  //Se recibe el cliente a editar
  useEffect(() => {
    sendRequest(
      {
        url: `/cliente/${idCliente}`,
      },
      transformData
    );
  }, [idCliente, sendRequest, transformData]);

  //Función que solicita la edición del cliente
  const updateCliente = (data: any) => {
    sendRequest(
      {
        url: `/cliente/${idCliente}`,
        method: "PATCH",
        body: data,
      },
      (variable: any) => {
        setShow(true);
      }
    );
  };

  //Mientras se carga el contenido se muestra un spinner
  if (!cliente) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h2>Edición del cliente {idCliente}</h2>
      {/** Alerta de guardado */}
      {show && (
        <SuccessMessage
          header="Cliente actualizado"
          body="El cliente ha sido actualizado de manera correcta."
          onCloseHandler={() => setShow(false)}
        />
      )}
      {/** Error del servidor */}
      {serverMessage && (
        <ServerMessage
          message={serverMessage}
          onCloseHandler={() => setServerMessage(undefined)}
        />
      )}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      {/** Formulario */}
      <Form onSubmit={handleSubmit(updateCliente)}>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" className="mb-3">
            <Form.Label>Razón Social</Form.Label>
            <Controller
              name="razon_social"
              control={control}
              rules={{
                required: true,
                maxLength: 100,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
            {errors.razon_social && (
              <Form.Text style={{ color: "red" }}>
                La razón social es obligatoria y no puede ser mayor a 100
                caracteres.
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col} md="6" className="mb-3">
            <Form.Label>Nombre Comercial</Form.Label>
            <Controller
              name="nombre_comercial"
              control={control}
              rules={{
                required: true,
                maxLength: 100,
              }}
              render={({ field }) => {
                return (
                  <Form.Control
                    {...field}
                    onChange={(e) =>
                      field.onChange(e.target.value.toUpperCase())
                    }
                  />
                );
              }}
            />
            {errors.nombre_comercial && (
              <Form.Text style={{ color: "red" }}>
                El Nombre Comercial es obligatorio y no puede ser mayor a 100
                caracteres.
              </Form.Text>
            )}
          </Form.Group>
        </Row>

        <Button
          style={{ backgroundColor: "#21618C", borderColor: "white" }}
          type="submit"
        >
          Guardar cambios
        </Button>
      </Form>
    </Container>
  );
};

export default ClienteEdit;
