import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Container, ProgressBar, Spinner } from "react-bootstrap";
import useFetch from "../../../hooks/useFetch";
import useDownloadPDF from "../../../hooks/useDownloadPDF";
import { ActividadIndex } from "../../../interfaces/ActividadIndexInterface";
import { ServicioAtendido } from "../../../types/ServiciosAtendidos";
import CustomTable from "../../Formularios/CustomTable/CustomTable";

//estilos
const classes = require("./ServiciosTable.module.css").default;

/**
 * ServiciosEnFalso Component
 * @description: Componente que muestra el paginado de los servicios que fueron salidas en falso.
 * @date 14/03/2023.
 * @param Props Ninguno
 * @returns JSX de la tabla de servicios en salida en falso.
 */

const ServiciosPorFirmar = () => {
  const [actividades, setActividades] = useState<ActividadIndex[]>();
  const { sendRequest } = useFetch();
  const [loading, setLoading] = useState(false);

  const { mostrarPDF } = useDownloadPDF(setLoading);

  //Función que trata los servicios traidos de la BD.
  const transformData = useCallback((variable: any) => {
    setActividades(variable.data);
  }, []);

  //Función que llama todos los servicios paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/actividad?includeAll=true&ultima_etapa[eq]=4",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  const columns = useMemo<ColumnDef<ServicioAtendido>[]>(
    () => [
      {
        header: "Órden de servicio",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Fecha y hora de la cita",
        accessorKey: "fecha",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Sucursal",
        accessorFn: (row) =>
          row.servicio.sucursal.nombre ? row.servicio.sucursal.nombre : "",
        id: "Sucursal",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Persona que capura",
        accessorFn: (row) => {
          let elemento = row.etapa.find(
            (element) => element.tipo_etapa_id === 1
          );
          if (elemento)
            return `${elemento?.usuario.nombre} ${elemento?.usuario.apellido_paterno}`;
          else return "";
        },
        id: "persona_captura",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Folio",
        accessorFn: (row) => (row.servicio.folio ? row.servicio.folio : ""),
        id: "Folio",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Técnico",
        accessorFn: (row) =>
          `${row.usuario.nombre} ${row.usuario.apellido_paterno} ${row.usuario.apellido_materno}`,
        id: "tecnico",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Tipo de servicio",
        accessorFn: (row) => row.tipo_servicio.nombre,
        id: "tipo_servicio",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "VIN",
        accessorFn: (row) => (row.unidad.vin ? row.unidad.vin : ""),
        id: "vin",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Placas",
        accessorFn: (row) => (row.unidad.placa ? row.unidad.placa : ""),
        id: "placa",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            {/** Boton de despliegue del PDF */}
            <Button
              title="Obtener el PDF de este servicio"
              disabled={loading}
              style={{ backgroundColor: "#5DADE2", borderColor: "white" }}
              onClick={() => mostrarPDF(info.row.original.id)}
            >
              <FontAwesomeIcon icon={faFilePdf} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [mostrarPDF, loading]
  );

  //Mientras se carga el contenido se muestra un spinner
  if (!actividades) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container style={{ paddingBottom: "1rem" }}>
      <h1>Servicios sin firma</h1>
      {loading && (
        <ProgressBar
          style={{ marginBottom: "1rem" }}
          label="Creando Archivo..."
          animated
          now={100}
        />
      )}
      <CustomTable
        {...{
          data: actividades,
          columns,
        }}
      />
    </Container>
  );
};

export default ServiciosPorFirmar;
