//librerías
import { useCallback, useEffect, useMemo, useState } from "react";
import { faFilePdf, faReceipt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Container, ProgressBar, Spinner } from "react-bootstrap";

//custom hooks
import useFetch from "../../../hooks/useFetch";

//componentes
import CustomTable from "../../Formularios/CustomTable/CustomTable";

//interfaces
import { ActividadIndexCobrado } from "../../../interfaces/ActividadIndexCobrado";

//types
import { ServicioCobrado } from "../../../types/ServicioCobrado";
import useDownloadPDF from "../../../hooks/useDownloadPDF";
import TicketCobroModal from "../../Formularios/TicketCobroModal/TicketCobroModal";
//estilos
const classes = require("./ServiciosTable.module.css").default;

/**
 * ServiciosCobrados Component
 * @description: Componente que muestra el paginado de los servicios que se han cobrado.
 * @date 06/03/2023.
 * @param Props Ninguno
 * @returns JSX de la tabla de servicios por cobrar.
 */

const ServiciosCobrados = () => {
  const { sendRequest } = useFetch();
  const [actividades, setActividades] = useState<ActividadIndexCobrado[]>();
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);
  const { mostrarPDF } = useDownloadPDF(setLoadingPDF);
  const [globalService, setGlobalService] = useState<ServicioCobrado>();

  //Función que trata los servicios traidos de la BD.
  const transformData = useCallback((variable: any) => {
    setActividades(variable.data);
  }, []);

  //get CObros
  const getCobros = (): void => {
    sendRequest(
      {
        url: "/actividad?ultima_etapa[gte]=3&onlyCobroNotNull=true&includeAll=true",
      },
      transformData
    );
  };

  //Función que llama los servicios
  useEffect(getCobros, [sendRequest, transformData]);

  //Definicion de las columnas de la tabla
  const columns = useMemo<ColumnDef<ServicioCobrado>[]>(
    () => [
      {
        header: "Órden de servicio",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Fecha y hora de la cita",
        accessorKey: "fecha",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Técnico",
        accessorFn: (row) =>
          `${row.usuario.nombre} ${row.usuario.apellido_paterno} ${row.usuario.apellido_materno}`,
        id: "tecnico",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Tipo de servicio",
        accessorFn: (row) => row.tipo_servicio.nombre,
        id: "tipo_servicio",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Ticket",
        accessorFn: (row) => row.unidad.ticket,
        id: "ticket",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Placas",
        accessorFn: (row) => (row.unidad.placa ? row.unidad.placa : ""),
        id: "placa",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Descripción",
        accessorFn: (row) =>
          row.descripcion_servicio ? row.descripcion_servicio : "",
        id: "descripcion",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Tipo de cobro",
        accessorFn: (row) => row.cobro.tipo_cobro.nombre,
        id: "tipo_cobro",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            {/** Boton de despliegue del PDF */}
            <Button
              title="Obtener el PDF de este servicio"
              style={{ backgroundColor: "#5DADE2", borderColor: "white" }}
              disabled={loadingPDF}
              onClick={() => mostrarPDF(info.row.original.id)}
            >
              <FontAwesomeIcon icon={faFilePdf} />
            </Button>
            {/** Boton de recibo */}
            <Button
              title="Ver recibo"
              style={{ backgroundColor: "#5DADE2", borderColor: "white" }}
              disabled={loadingPDF}
              onClick={() => setGlobalService(info.row.original)}
            >
              <FontAwesomeIcon icon={faReceipt} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [mostrarPDF, loadingPDF]
  );

  //Mientras se carga el detalle se muestra un spinner
  if (!actividades) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container style={{ paddingBottom: "1rem" }}>
      <h1>Servicios cobrados</h1>
      {/** ProgressBar */}
      {loadingPDF && (
        <ProgressBar
          style={{ marginBottom: "1rem" }}
          label="Creando PDF..."
          animated
          now={100}
        />
      )}
      {/** Tabla de actividades */}
      <CustomTable
        {...{
          data: actividades,
          columns,
        }}
      />
      <TicketCobroModal
        servicio={globalService}
        handleCloseModal={() => setGlobalService(undefined)}
        getCobros={getCobros}
      />
    </Container>
  );
};

export default ServiciosCobrados;
