//Librerías
import { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

//Componentes
import useFetch from "../../../hooks/useFetch";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useGetData } from "../../../hooks/useGetData";

//interfaces
import { Marca, Unidad } from "../../../interfaces/ServiciosInterface";
import { TipoServicio } from "../../../interfaces/ActividadIndexInterface";
import { ActividadShow } from "../../../interfaces/ActividadShowInterface";
import { SucursalInterface } from "../../../interfaces/SucursalInterface";
import { EstadoInterface } from "../../../interfaces/EstadoInterface";

//estilos
const classes = require("./ServicioEdit.module.css").default;

interface Contacto {
  id: number;
  nombre: string;
  correo: string;
  servicio_id: number;
  telefono_movil: string;
  telefono_fijo: string;
}

/**
 * ServicioEdit Component
 * @description: Componente que permite editar un servicio.
 * @date 17/12/2022.
 * @param Props No aplica
 * @returns JSX formulario del servicio.
 */

const ServicioEdit = () => {
  const { idActividad } = useParams();
  const { sendRequest } = useFetch();
  const [actividad, setActividad] = useState<ActividadShow>();
  const navigate = useNavigate();
  const [contacto, setContacto] = useState<Contacto[]>([]);
  const [unidad, setUnidad] = useState<Unidad>();
  const [marcasUnidades, setMarcasUnidades] = useState<Marca[]>([]);
  const [tiposServicios, setTiposServicios] = useState<TipoServicio[]>([]);
  const [tipoServicio, setTiposServicio] = useState<number>();
  const [estados, setEstados] = useState<EstadoInterface[]>();

  const { data: sucursales } = useGetData("/sucursal", "sucursal");

  let currentContacto: Contacto[] = [];
  let bodyPost = {};

  useEffect(() => {
    sendRequest(
      {
        url: `/estado`,
      },
      (variable: any) => {
        setEstados(
          variable.data.map((estado: EstadoInterface) => {
            return { value: estado.id, label: estado.nombre };
          })
        );
      }
    );
  }, [sendRequest]);

  //Validaciones del formulario
  const formSchema = Yup.object().shape({
    folio: Yup.string().nullable().optional(),
    sucursal: Yup.object().required("Es necesario seleccionar la sucursal"),
    fecha: Yup.string().nullable().optional(),
    hora: Yup.string().nullable().optional(),
    domicilio_sucursal: Yup.string()
      .nullable()
      .optional()
      .max(200, "El domicilio no puede ser mayor a 200 caracteres"),
    estado: Yup.object().required("Es necesario seleccionar un estado"),
    latitud_sucursal: Yup.string().required("La latitud es obligatoria"),
    longitud_sucursal: Yup.string().required("La longitud es obligatoria"),
    notas_sucursal: Yup.string()
      .nullable()
      .optional()
      .max(200, "Las observaciones no puede ser mayores a 200 caracteres"),
    ultima_etapa: Yup.string().optional(),
  });

  //Elementos del formulario
  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  //Función que trata los datos de la actividad recibida.
  const transformData = useCallback(
    (variable: any) => {
      setActividad(variable.data);
      setContacto(variable.data.servicio.contacto);
      setUnidad(variable.data.unidad);
      setTiposServicio(variable.data.tipo_servicio.id);
      reset({
        sucursal: {
          value: variable.data.servicio.sucursal.id,
          label: variable.data.servicio.sucursal.nombre,
        },
        estado: {
          value: variable.data.servicio.estado.id,
          label: variable.data.servicio.estado.nombre,
        },
        domicilio_sucursal: variable.data.servicio.domicilio_sucursal,
        latitud_sucursal: variable.data.servicio.latitud_sucursal,
        longitud_sucursal: variable.data.servicio.longitud_sucursal,
        notas_sucursal: variable.data.servicio.notas_sucursal,
        folio: variable.data.servicio.folio,
        fecha: variable.data.fecha ? variable.data.fecha?.substring(0, 10) : "",
        hora: variable.data.fecha
          ? variable.data.servicio.fecha?.substring(11, 16)
          : "",
        ultima_etapa: variable.data.ultima_etapa,
      });
    },
    [reset]
  );

  //Trata las marca de unidades
  const transformDataMarcaUnidad = useCallback((variable: any) => {
    setMarcasUnidades(variable.data);
  }, []);

  //Trae a los tuipos de servicio
  const transformDataTipoServicio = useCallback((variable: any) => {
    setTiposServicios(variable.data);
  }, []);

  //Se recibe la actividad a editar.
  useEffect(() => {
    sendRequest(
      {
        url: `/actividad/${idActividad}?includeAll=true`,
      },
      transformData
    );
  }, [idActividad, sendRequest, transformData]);

  //Se recibe las marcas de unidades.
  useEffect(() => {
    sendRequest(
      {
        url: `/marca_unidad`,
      },
      transformDataMarcaUnidad
    );
  }, [sendRequest, transformDataMarcaUnidad]);

  //Se reciben los tipos de servicio
  useEffect(() => {
    sendRequest(
      {
        url: `/tipo_servicio?estatus[eq]=1`,
      },
      transformDataTipoServicio
    );
  }, [sendRequest, transformDataTipoServicio]);

  //Función que es llamada al terminar el formulario.
  const editServicio = (data: any) => {
    bodyPost = {
      actividad_id: actividad?.id,
      ...data,
      contacto: [...contacto],
      unidad: unidad,
      tipo_servicio: tipoServicio,
      fecha: data.fecha ? `${data.fecha} ${data.hora}` : null,
      editar: true,
    };
    sendRequest(
      {
        url: `/servicio/${actividad?.servicio.id}`,
        method: "PATCH",
        body: bodyPost,
      },
      (variable: any) => {
        toast.success("Se editó el servicio correctamente", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate(-1);
      }
    );
  };

  //mientras se carga el detalle se muestra un spinner
  if (!actividad) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h1>Editar el servicio {idActividad}</h1>
      <h2 className={classes.subtitulo}>Datos generales </h2>
      <Form onSubmit={handleSubmit(editServicio)}>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Fecha de servicio</Form.Label>
            <Form.Control type="date" {...register("fecha")} disabled />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Hora de servicio</Form.Label>
            <Form.Control {...register("hora")} type="time" disabled />
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Folio</Form.Label>
            <Form.Control
              {...register("folio", { maxLength: 50 })}
              placeholder="Ingresar el folio de órden de venta"
            />
            {errors.folio && (
              <Form.Text style={{ color: "red" }}>
                {errors.folio?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>

        <h2 className={classes.subtitulo}>Datos de la sucursal </h2>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Sucursal</Form.Label>
            <Controller
              name="sucursal"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={sucursales?.map((sucursal: SucursalInterface) => {
                    return {
                      value: sucursal.id,
                      label: sucursal.nombre,
                      domicilio: sucursal.domicilio,
                      latitud: sucursal.latitud,
                      longitud: sucursal.longitud,
                      estado: sucursal.estado,
                    };
                  })}
                  onChange={(sucursal: any) => {
                    setValue("sucursal", {
                      value: sucursal.value,
                      label: sucursal.label,
                    });
                    setValue("domicilio_sucursal", sucursal.domicilio);
                    setValue("latitud_sucursal", sucursal.latitud);
                    setValue("longitud_sucursal", sucursal.longitud);
                    setValue("estado", {
                      value: sucursal.estado.id,
                      label: sucursal.estado.nombre,
                    });
                  }}
                  placeholder="Seleccionar..."
                />
              )}
            />
            {errors.sucursal && (
              <Form.Text style={{ color: "red" }}>
                {errors.sucursal?.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Domicilio</Form.Label>
            <Form.Control
              {...register("domicilio_sucursal", {
                maxLength: 200,
              })}
            />
            {errors.domicilio_sucursal && (
              <Form.Text style={{ color: "red" }}>
                {errors.domicilio_sucursal?.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Estado</Form.Label>
            <Controller
              name="estado"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  options={estados}
                  placeholder="Seleccionar..."
                />
              )}
            />
            {errors.estado && (
              <Form.Text style={{ color: "red" }}>
                {errors.estado?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>Latitud</Form.Label>
            <Form.Control
              type="number"
              step={0.00000000001}
              min={-90}
              max={90}
              {...register("latitud_sucursal", {
                maxLength: 50,
                required: true,
              })}
            />
            {errors.latitud_sucursal && (
              <Form.Text style={{ color: "red" }}>
                {errors.latitud_sucursal?.message}
              </Form.Text>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>Longitud</Form.Label>
            <Form.Control
              type="number"
              step={0.00000000001}
              min={-180}
              max={180}
              {...register("longitud_sucursal", {
                maxLength: 50,
                required: true,
              })}
            />
            {errors.longitud_sucursal && (
              <Form.Text style={{ color: "red" }}>
                {errors.longitud_sucursal?.message}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>Observaciones</Form.Label>
            <Form.Control
              {...register("notas_sucursal", { maxLength: 200 })}
              as="textarea"
            />
            {errors.notas_sucursal && (
              <Form.Text style={{ color: "red" }}>
                {errors.notas_sucursal?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Row>
        {contacto.length > 0 && (
          <Alert variant="secondary">
            <Alert.Heading>Datos de contacto</Alert.Heading>
            <>
              {contacto.map((contact: any, index) => {
                return (
                  <Row key={contact.id} className="mb-3">
                    <Form.Group as={Col}>
                      <Form.Label>Nombre del contacto</Form.Label>
                      <Form.Control
                        value={contact.nombre as string}
                        onChange={(event) => {
                          currentContacto = contacto;
                          currentContacto[index].nombre = event.target.value;
                          setContacto([...currentContacto]);
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        value={contact.correo as string}
                        onChange={(event) => {
                          currentContacto = contacto;
                          currentContacto[index].correo = event.target.value;
                          setContacto([...currentContacto]);
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Teléfono móvil</Form.Label>
                      <Form.Control
                        value={contact.telefono_movil as string}
                        onChange={(event) => {
                          currentContacto = contacto;
                          currentContacto[index].telefono_movil =
                            event.target.value;
                          setContacto([...currentContacto]);
                        }}
                      />
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>Teléfono fijo</Form.Label>
                      <Form.Control
                        value={
                          contact.telefono_fijo
                            ? (contact.telefono_fijo as string)
                            : ""
                        }
                        onChange={(event) => {
                          currentContacto = contacto;
                          currentContacto[index].telefono_fijo =
                            event.target.value;
                          setContacto([...currentContacto]);
                        }}
                      />
                    </Form.Group>
                  </Row>
                );
              })}
            </>
          </Alert>
        )}

        {unidad && (
          <div>
            <h2 className={classes.subtitulo}>Datos de la unidad </h2>
            <Row key={actividad.unidad.id} className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Tipo Servicio</Form.Label>
                <Form.Select
                  value={tipoServicio}
                  onChange={(event) => setTiposServicio(+event.target.value)}
                >
                  {tiposServicios.map((tiposServicio) => (
                    <option key={tiposServicio.nombre} value={tiposServicio.id}>
                      {tiposServicio.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Marca</Form.Label>
                <Form.Select
                  value={
                    unidad.marca_unidad_id
                      ? unidad.marca_unidad_id
                      : unidad.marca_unidad?.id
                  }
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, marca_unidad_id: +event.target.value };
                    });
                  }}
                >
                  {marcasUnidades.map((marcaUnidad) => (
                    <option key={marcaUnidad.nombre} value={marcaUnidad.id}>
                      {marcaUnidad.nombre}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Submarca</Form.Label>
                <Form.Control
                  value={unidad.submarca as string}
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, submarca: event.target.value };
                    });
                  }}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Modelo</Form.Label>
                <Form.Control
                  value={unidad.modelo as string}
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, modelo: event.target.value };
                    });
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label>Vin</Form.Label>
                <Form.Control
                  value={unidad.vin as string}
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, vin: event.target.value };
                    });
                  }}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label>Placas</Form.Label>
                <Form.Control
                  value={unidad.placa as string}
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, placa: event.target.value };
                    });
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label>Color</Form.Label>
                <Form.Control
                  value={unidad.color as string}
                  onChange={(event) => {
                    setUnidad((prev: any) => {
                      return { ...prev, color: event.target.value };
                    });
                  }}
                />
              </Form.Group>
            </Row>
          </div>
        )}

        <Button
          style={{
            backgroundColor: "#21618C",
            borderColor: "white",
            marginTop: "10px",
          }}
          type="submit"
        >
          Guardar
        </Button>
      </Form>
    </Container>
  );
};

export default ServicioEdit;
