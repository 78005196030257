import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  faEye,
  faPen,
  faSquarePlus,
  faUserPlus,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef } from "@tanstack/react-table";
import { Button, Container, Spinner } from "react-bootstrap";
import CustomTable from "../../../../components/Formularios/CustomTable/CustomTable";
import useFetch from "../../../../hooks/useFetch";
import { UsuarioInterface } from "../../../../interfaces/UsuarioInterface";
import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";

//context
import { AuthContext } from "../../../../context/AuthContext";
import { toast } from "react-toastify";

//estilos
const classes = require("./UsuariosTable.module.css").default;

const UsuariosTable = () => {
  const authContext = useContext(AuthContext);
  const { sendRequest, error, setError } = useFetch();
  const [usuarios, setUsuarios] = useState<UsuarioInterface[]>();
  const navigate = useNavigate();

  //Función que trata los usuarios traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setUsuarios(variable.data);
  }, []);

  //Función que llama todos los usuarios paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/usuario?&rol_id[gt]=1&includeRol=true",
      },
      transformData
    );
  }, [sendRequest, transformData]);

  //Función que desactiva a un usuario.
  const changeUserStatus = useCallback(
    (idUsuario: number) => {
      if (
        window.confirm(
          "¿Seguro que desea cambiar el estatus del usuario? El usuario no podrá acceder al sistema si está deshabilitado"
        )
      ) {
        sendRequest(
          {
            url: `/usuario/${idUsuario}`,
            method: "DELETE",
          },
          (variable: any) => {
            setUsuarios([]);
            toast.success("El usuario ha cambiado de estatus.", {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            sendRequest(
              {
                url: "/usuario?includeRol=true",
              },
              transformData
            );
          }
        );
      }
    },
    [sendRequest, transformData]
  );

  const columns = useMemo<ColumnDef<UsuarioInterface>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        header: "Correo",
        accessorFn: (row) => row.correo,
        id: "Correo",
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "nombre",
        header: () => "Nombre",
      },
      {
        accessorKey: "apellido_paterno",
        header: () => "Apellido paterno",
      },
      {
        accessorKey: "apellido_materno",
        header: () => "Apellido materno",
      },
      {
        header: "Estado",
        accessorFn: (row) => (row.estado?.nombre ? row.estado.nombre : ""),
        id: "estado",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Rol",
        accessorFn: (row) => (row.rol?.nombre ? row.rol.nombre : ""),
        id: "rol",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Estatus",
        cell: (info) => info.getValue(),
        accessorFn: (row) => (row.estatus ? "Activo" : "Inactivo"),
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <Button
              title="Ver detalles"
              style={{ backgroundColor: "#5DADE2", borderColor: "white" }}
              onClick={() =>
                navigate(
                  `/main/catalogos/usuarios/details/${info.row.original.id}`
                )
              }
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
            <Button
              title="Editar usuario"
              style={{ backgroundColor: "#2874A6", borderColor: "white" }}
              onClick={() =>
                navigate(
                  `/main/catalogos/usuarios/edit/${info.row.original.id}`
                )
              }
              disabled={!authContext.tokenCan("Usuario:update")}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button>
            {/** Botón para ver eliminar*/}
            <Button
              title={
                info.row.original.estatus
                  ? "Deshabilitar usuario"
                  : "Habilitar usuario"
              }
              style={{ backgroundColor: "#0d5475", borderColor: "white" }}
              onClick={() => changeUserStatus(info.row.original.id)}
            >
              <FontAwesomeIcon
                icon={info.row.original.estatus ? faUserSlash : faUserPlus}
              />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [changeUserStatus, navigate, authContext]
  );

  //Mientras se carga el contenido se muestra un spinner
  if (!usuarios) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h2>Lista de usuarios</h2>
      {authContext.tokenCan("Usuario:store") && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "10px",
          }}
        >
          <Button
            title="Agregar un nuevo usuario"
            style={{ backgroundColor: "#21618C", borderColor: "white" }}
            onClick={() => navigate(`/main/catalogos/usuarios/crear`)}
          >
            <FontAwesomeIcon icon={faSquarePlus} /> Nuevo usuario
          </Button>
        </div>
      )}

      {/** Alerta de permisos */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      <CustomTable
        {...{
          data: usuarios,
          columns,
        }}
      />
    </Container>
  );
};

export default UsuariosTable;
