import { Outlet } from "react-router-dom";

const Busqueda = () => {
  return (
    <div style={{ marginTop: "70px" }}>
      <Outlet />
    </div>
  );
};

export default Busqueda;
