import {
  faBroom,
  faFileExcel,
  faFilter,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Container,
  Form,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { API } from "../../global/global";
import { AuthContext } from "../../context/AuthContext";

const ReportesMain = () => {
  const authContext = useContext(AuthContext);
  const [timestamp, setTimestamp] = useState({ desde: "", hasta: "" });
  const [loading, setLoading] = useState<boolean>(false);
  const [etapa, setEtapa] = useState<number[]>([]);
  const [porCobrar, setPorCobrar] = useState<boolean>(false);
  const [cobrados, setCobrados] = useState<boolean>(false);
  const [porPagar, setPorPagar] = useState<boolean>(false);
  const [pagados, setPagados] = useState<boolean>(false);

  //Función que llama al PDF para descargarlo.
  const descargaExcel = async () => {
    if (!timestamp.desde || !timestamp.hasta) {
      toast.error("Es necesario colocar ambas fechas", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (timestamp.desde > timestamp.hasta) {
      toast.error("Es necesario que la primera fecha sea menor a la segunda", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    setLoading(true);
    const response = await fetch(`${API}/export_actividades`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + authContext.token,
      },
      body: JSON.stringify({
        from: timestamp.desde,
        to: timestamp.hasta,
        etapas: etapa,
        porCobrar,
        cobrados,
        porPagar,
        pagados,
      }),
    });

    if (!response.ok) {
      if (response.status === 500) throw new Error("Request failed!");
      setLoading(false);
      return;
    }

    const data = await response.blob();
    //se crea el link con la descarga.
    let url = window.URL.createObjectURL(data),
      anchor = document.createElement("a");
    document.body.appendChild(anchor);
    anchor.href = url;
    anchor.download = `Servicios_${timestamp.desde}_${timestamp.hasta}.xlsx`;
    anchor.click();
    setLoading(false);
    // Se elimna el link
    window.URL.revokeObjectURL(url);
    document.body.removeChild(anchor);
  };

  return (
    <Container>
      <h1>Reportes</h1>
      <Accordion defaultActiveKey={["0", "1"]} alwaysOpen>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FontAwesomeIcon
              icon={faLayerGroup}
              style={{
                fontSize: "20px",
                marginRight: "20px",
              }}
            />
            Estatus de servicios
          </Accordion.Header>
          <Accordion.Body>
            <Form.Text className="text-muted">
              Seleccionar los estatus de los servicios que se desean exportar.
            </Form.Text>
            <div key={`inline-checkbox`} className="mb-3">
              <Form.Check
                inline
                type="switch"
                id="custom-switch1"
                label="Servicios atendidos"
                onChange={(val) => {
                  if (val.currentTarget.checked) {
                    setEtapa((prev: number[]) => [...prev, 3]);
                  } else {
                    setEtapa((prev) => prev.filter((element) => element !== 3));
                  }
                }}
              />
              <Form.Check
                inline
                type="switch"
                id="custom-switch2"
                label="Servicios por firmar"
                onChange={(val) => {
                  if (val.currentTarget.checked) {
                    setEtapa((prev: number[]) => [...prev, 4]);
                  } else {
                    setEtapa((prev) => prev.filter((element) => element !== 4));
                  }
                }}
              />
              <Form.Check
                inline
                type="switch"
                id="custom-switch3"
                label="Salidas en falso"
                onChange={(val) => {
                  if (val.currentTarget.checked) {
                    setEtapa((prev: number[]) => [...prev, 5]);
                  } else {
                    setEtapa((prev) => prev.filter((element) => element !== 5));
                  }
                }}
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            <FontAwesomeIcon
              icon={faFilter}
              style={{
                fontSize: "20px",
                marginRight: "20px",
              }}
            />
            Filtros
          </Accordion.Header>
          <Accordion.Body>
            <Form>
              <Form.Text className="text-muted">
                Seleccionar los filtros a aplicar a los datos.
              </Form.Text>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  title="Limpiar filtros"
                  style={{
                    backgroundColor: "#21618C",
                    borderColor: "white",
                  }}
                  onClick={() => {
                    setTimestamp({ desde: "", hasta: "" });
                    setCobrados(false);
                    setPorCobrar(false);
                    setPagados(false);
                    setPorPagar(false);
                  }}
                >
                  <FontAwesomeIcon icon={faBroom} />
                </Button>
              </div>
              <Row className="mb-3" style={{ textAlign: "left" }}>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check
                    inline
                    type="radio"
                    id="custom-switch4"
                    label="Servicios por cobrar"
                    name="group1"
                    checked={porCobrar}
                    onChange={() => {
                      setCobrados(false);
                      setPorCobrar(true);
                      setPorPagar(false);
                      setPagados(false);
                    }}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="custom-switch5"
                    label="Servicios cobrados"
                    checked={cobrados}
                    name="group1"
                    onChange={() => {
                      setPorCobrar(false);
                      setCobrados(true);
                      setPorPagar(false);
                      setPagados(false);
                    }}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="custom-switch5"
                    label="Servicios por pagar"
                    checked={porPagar}
                    name="group1"
                    onChange={() => {
                      setPorPagar(true);
                      setPagados(false);
                      setPorCobrar(false);
                      setCobrados(false);
                    }}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="custom-switch5"
                    label="Servicios pagados"
                    checked={pagados}
                    name="group1"
                    onChange={() => {
                      setPorPagar(false);
                      setPagados(true);
                      setPorCobrar(false);
                      setCobrados(false);
                    }}
                  />
                </div>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col}>
                  <Form.Label>Del:</Form.Label>
                  <Form.Control
                    type="date"
                    min="2022-12-01"
                    max="2030-12-31"
                    value={timestamp.desde}
                    onChange={(event) =>
                      setTimestamp((prev) => {
                        return { ...prev, desde: event.target.value };
                      })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label>Al:</Form.Label>
                  <Form.Control
                    type="date"
                    min="2022-12-01"
                    max="2030-12-31"
                    value={timestamp.hasta}
                    onChange={(event) =>
                      setTimestamp((prev) => {
                        return { ...prev, hasta: event.target.value };
                      })
                    }
                  />
                </Form.Group>
              </Row>
            </Form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br />
      {loading && (
        <ProgressBar
          style={{ marginBottom: "1rem" }}
          label="Creando Excel..."
          animated
          now={100}
        />
      )}
      <Button
        style={{
          backgroundColor: "#21618C",
          borderColor: "white",
          marginBottom: "50px",
        }}
        onClick={descargaExcel}
        disabled={
          etapa.length === 0 && !cobrados && !porCobrar && !pagados && !porPagar
        }
      >
        <FontAwesomeIcon icon={faFileExcel} /> Descargar
      </Button>
    </Container>
  );
};

export default ReportesMain;
