import { Dispatch, SetStateAction, useState } from "react";
import { UnidadInterface } from "../../../interfaces/UnidadInterface";
import { Button, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faSpinner,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { UnidadEditarModal } from "../UnidadEditarModal/UnidadEditarModal";
import { UnidadConfigurarModal } from "../UnidadConfigurarModal/UnidadConfigurarModal";
import {
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { useGetData } from "../../../hooks/useGetData";
import { UnidadServiciosModal } from "../UnidadServiciosModal/UnidadServiciosModal";

interface Props {
  unidadesList: UnidadInterface[] | [];
  // unidades: UnidadInterface[] | [];
  // setUnidades: Dispatch<SetStateAction<UnidadInterface[]>>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  setActividad: Dispatch<SetStateAction<any[]>>;
  actividad: any[];
}

/**
 * UnidadList Component
 * @description: Componente que muestra las unidades y se muestran las selccionadas
 * @date 16/12/2022.
 * @param Props Ninguno
 * @returns JSX de la tabla de clientes.
 */
export const UnidadList = ({
  unidadesList,
  setValue,
  getValues,
  setActividad,
  actividad,
}: Props) => {
  const [unidadEditar, setUnidadEditar] = useState<UnidadInterface | null>(
    null
  );

  const [unidadConf, setUnidadConf] = useState<UnidadInterface | null>(null);

  const [unidadServicios, setUnidadServicios] =
    useState<UnidadInterface | null>(null);

  //Funciones
  const handleCloseEditarModal = () => setUnidadEditar(null);
  const handleCloseConfModal = () => setUnidadConf(null);
  const handleCloseServiciosModal = () => setUnidadServicios(null);

  //Hooks
  const { data: actividades, isLoading } = useGetData(
    "/actividad?includeAll=true",
    "actividadesUnidadTipo"
  );

  if (isLoading) {
    return (
      <FontAwesomeIcon
        icon={faSpinner}
        pulse
        style={{ fontSize: "100", marginTop: 30 }}
      />
    );
  }

  return (
    <>
      <Row>
        <div
          className="table-responsive"
          style={{
            maxHeight: "300px",
          }}
        >
          <table className="table table-striped">
            <thead style={{ backgroundColor: "#04144c", color: "white" }}>
              <tr>
                <th scope="col">Revisiones</th>
                <th scope="col">Ticket</th>
                <th scope="col">VIN</th>
                <th scope="col">Placa</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {unidadesList && unidadesList.length === 0 && !isLoading && (
                <tr key={"unidad-nada"} className="table-secondary">
                  <td colSpan={5}>
                    No existen unidades asociadas a este cliente.
                  </td>
                </tr>
              )}
              {unidadesList?.map((unidad: any) => (
                <tr key={`unidad-${unidad.id}`}>
                  <td>
                    <Button
                      title="Detalle de revisiones"
                      style={{
                        backgroundColor: "#5DADE2",
                        borderColor: "white",
                      }}
                      onClick={() => {
                        setUnidadServicios(unidad);
                      }}
                    >
                      {
                        actividades?.filter(
                          (actividad: any) =>
                            actividad.unidad.id === unidad.id &&
                            actividad.tipo_servicio.revision === true
                        ).length
                      }
                    </Button>
                  </td>
                  <td>{unidad.ticket}</td>
                  <td>{unidad.vin}</td>
                  <td>{unidad.placa}</td>
                  <td>
                    <Button
                      title="Agregar unidad"
                      style={{
                        backgroundColor: "#5DADE2",
                        borderColor: "white",
                      }}
                      onClick={() => {
                        setUnidadConf(unidad);
                      }}
                    >
                      <FontAwesomeIcon icon={faAdd} />
                    </Button>
                    <Button
                      title="Editar unidad"
                      style={{
                        backgroundColor: "#5DADE2",
                        borderColor: "white",
                      }}
                      onClick={() => setUnidadEditar(unidad)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Row>

      {actividad && actividad.length !== 0 && (
        <Row className="mt-3">
          <div
            className="table-responsive"
            style={{
              maxHeight: "300px",
            }}
          >
            <h4>Unidades asignadas a este servicio</h4>
            <table className="table table-striped">
              <thead style={{ backgroundColor: "#04144c", color: "white" }}>
                <tr>
                  <th scope="col">Ticket</th>
                  <th scope="col">VIN</th>
                  <th scope="col">Placa</th>
                  <th scope="col">Tipo de servicio</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {actividad?.map((a) => (
                  <tr
                    key={`unidad-elegida-${a.unidad.id}-${a.tipo_servicio_id}`}
                    className="table-secondary"
                  >
                    <td>{a.unidad.ticket}</td>
                    <td>{a.unidad.vin}</td>
                    <td>{a.unidad.placa}</td>
                    <td>{a.tipoServicio.nombre}</td>
                    <td>
                      <Button
                        title="Quitar unidad"
                        style={{
                          backgroundColor: "#5DADE2",
                          borderColor: "white",
                        }}
                        onClick={() => {
                          setActividad(
                            actividad.filter(
                              (element) =>
                                !(
                                  element.unidad.id === a.unidad.id &&
                                  element.tipo_servicio_id ===
                                    a.tipo_servicio_id
                                )
                            )
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Row>
      )}

      {unidadEditar && (
        <UnidadEditarModal
          unidad={unidadEditar}
          handleCloseModal={handleCloseEditarModal}
        />
      )}

      {unidadConf && (
        <UnidadConfigurarModal
          unidad={unidadConf}
          handleCloseModal={handleCloseConfModal}
          setValue={setValue}
          getValues={getValues}
          setActividad={setActividad}
          actividad={actividad}
        />
      )}

      {unidadServicios && (
        <UnidadServiciosModal
          actividades={actividades}
          unidad={unidadServicios}
          handleCloseModal={handleCloseServiciosModal}
        />
      )}
    </>
  );
};
