//librerías
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";

//context
import { AuthContext } from "../../../../context/AuthContext";

//componentes
import { Button, Container, Spinner } from "react-bootstrap";
import {
  faPen,
  faRightLeft,
  faSquarePlus,
} from "@fortawesome/free-solid-svg-icons";

//interfaces
import { UnidadInterface } from "../../../../interfaces/UnidadInterface";

//hooks
import useFetch from "../../../../hooks/useFetch";
import { ColumnDef } from "@tanstack/react-table";
import CustomTable from "../../../Formularios/CustomTable/CustomTable";
import ForbiddenMessage from "../../../Mensajes/ForbiddenMessage";
import ChangeUnitModal from "../../../Formularios/ChangeUnitModal/ChangeUnitModal";
import { ClientesInterface } from "../../../../interfaces/ClientesInterface";

//estilos
const classes = require("./UnidadesTable.module.css").default;

const UnidadesTable = () => {
  const { idCliente } = useParams();
  const authContext = useContext(AuthContext);
  //variables de estado
  const [unidades, setUnidades] = useState<UnidadInterface[]>();
  const [clientes, setClientes] = useState<ClientesInterface[]>();
  const [selectedUnit, setSelectedUnit] = useState<UnidadInterface>();
  const { sendRequest, error, setError } = useFetch();
  const navigate = useNavigate();

  //Función que trata los clientes traídos de la BD.
  const transformData = useCallback((variable: any) => {
    setUnidades(variable.data);
  }, []);

  //Función que llama todos los clientes paginados
  useEffect(() => {
    sendRequest(
      {
        url: `/unidad?includeMarcaUnidad=true&includeCliente=true&cliente_id[eq]=${idCliente}`,
      },
      transformData
    );
  }, [sendRequest, transformData, idCliente]);

  //Función que trata los clientes traídos de la BD.
  const transformDataClientes = useCallback((variable: any) => {
    setClientes(variable.data);
  }, []);

  //Función que llama todos los clientes paginados
  useEffect(() => {
    sendRequest(
      {
        url: "/cliente?estatus[eq]=1",
      },
      transformDataClientes
    );
  }, [sendRequest, transformDataClientes]);

  const columns = useMemo<ColumnDef<UnidadInterface>[]>(
    () => [
      {
        header: "#",
        accessorKey: "id",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        header: "Submarca",
        accessorFn: (row) => (row.submarca ? row.submarca : ""),
        id: "Submarca",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Modelo",
        accessorFn: (row) => (row.modelo ? row.modelo : ""),
        id: "Modelo",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Económico",
        accessorFn: (row) => (row.economico ? row.economico : ""),
        id: "Económico",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "VIN",
        accessorFn: (row) => (row.vin ? row.vin : ""),
        id: "VIN",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Placa",
        accessorFn: (row) => (row.placa ? row.placa : ""),
        id: "Placa",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Color",
        accessorFn: (row) => (row.color ? row.color : ""),
        id: "Color",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Voltaje",
        accessorFn: (row) => (row.voltaje ? row.voltaje : ""),
        id: "Voltaje",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        enableColumnFilter: false,
      },
      {
        header: "Marca",
        accessorFn: (row) =>
          row.marca_unidad?.nombre ? row.marca_unidad?.nombre : "",
        id: "Marca",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Acciones",
        cell: (info) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            {/* <Button
              title="Editar unidad"
              style={{ backgroundColor: "#2874A6", borderColor: "white" }}
              onClick={() =>
                navigate(
                  `/main/catalogos/clientes/edit/${info.row.original.id}`
                )
              }
              disabled={!authContext.tokenCan("Cliente:update")}
            >
              <FontAwesomeIcon icon={faPen} />
            </Button> */}
            {/** Botón para migrar unidades*/}
            <Button
              title="Migrar unidad"
              style={{ backgroundColor: "#2874A6", borderColor: "white" }}
              onClick={() => setSelectedUnit(info.row.original)}
              disabled={!authContext.tokenCan("Unidad:administrar")}
            >
              <FontAwesomeIcon icon={faRightLeft} />
            </Button>
          </div>
        ),
        footer: (props) => props.column.id,
      },
    ],
    [navigate, authContext]
  );

  //Mientras se carga el contenido se muestra un spinner
  if (!unidades) {
    return (
      <div className={classes.spinnerContainer}>
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }

  return (
    <Container>
      <h2>Lista de unidades</h2>
      {/** Botón para agregar usuario */}
      {authContext.tokenCan("Cliente:store") && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            title="Agregar una unidad a este cliente"
            style={{
              backgroundColor: "#21618C",
              borderColor: "white",
              marginBottom: "10px",
            }}
            onClick={() => navigate(`/main/catalogos/clientes/crear`)}
          >
            <FontAwesomeIcon icon={faSquarePlus} /> Agregar unidad
          </Button>
        </div>
      )}

      {/** Alerta de permisos */}
      {error === 403 && (
        <ForbiddenMessage onCloseHandler={() => setError(null)} />
      )}
      <CustomTable
        {...{
          data: unidades,
          columns,
        }}
      />
      <ChangeUnitModal
        unidad={selectedUnit}
        resetUnit={setSelectedUnit}
        clientes={clientes}
        transformData={transformData}
        idCliente={idCliente}
      />
    </Container>
  );
};

export default UnidadesTable;
